import * as React from "react";
import { useState, useEffect } from "react";
import type { HeadFC } from "gatsby";
import { Layout } from "../components";

import "../assets/scss/pages/revealing.scss";

import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Lazy, Navigation, Thumbs } from "swiper";

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/lazy';

import { buildSwiperSlides, ILayer, OPENSEA_LAYERS_MARKET, OPENSEA_TEARS_MARKET, OPENSEA_TEARS_MARKET_ALPHA, OPENSEA_TEARS_MARKET_BETA, TearTypes } from "../helpers";

SwiperCore.use([Autoplay, Lazy, Navigation, Thumbs]);

const RevealingPage = () => {

	const [collectionNFTCount, setCollectionNFTCount] = useState('0');

	const [availableLayers, setAvailableLayers] = useState<Array<ILayer>>([]);
	const [availableTearsAlpha, setAvailableTearsAlpha] = useState(0);
	const [availableTearsBeta, setAvailableTearsBeta] = useState(0);
	const [selectedLayer, setSelectedLayer] = useState<ILayer | false>(false);
	const [selectedLayerIndex, setSelectedLayerIndex] = useState(0);

	const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
	// const [layersSwiper, setLayersSwiper] = useState<SwiperCore | null>(null);


	useEffect(() => {
		let nft_count = 2803;
		setCollectionNFTCount((nft_count / 1000).toFixed(3).replace(/\./, ','));


		let _TEMP_available_layers: Array<ILayer> = [];

		//? Set a temporary array of available layers
		if (location.search.indexOf('layers') !== -1) {
			for (let i = 0; i < 10; i++) {
				let _layer: ILayer = {
					title: Math.floor(Math.random() * 2803 + 1).toString(),
					traits: {
						background: {
							value: 'Regular',
							count: Math.floor(Math.random() * 2803 + 1),
							isRare: Math.random() > 0.5,
						},
						border: {
							value: 'Regular',
							count: Math.floor(Math.random() * 2803 + 1),
							isRare: Math.random() > 0.5,
						},
					},
					img: '/assets/img/temp/canvas_blank_xs.png',
					img2x: '/assets/img/temp/canvas_blank_xs@2x.png',
					sources: {
						768: {
							img: '/assets/img/temp/canvas_blank_md.png',
							img2x: '/assets/img/temp/canvas_blank_md@2x.png',
						},
						1200: {
							img: '/assets/img/temp/canvas_blank_xl.png',
							img2x: '/assets/img/temp/canvas_blank_xl@2x.png',
						},
					},
				};
				_layer.tears = [];
				for (let ii = 0, l = Math.floor(Math.random() * 3); ii < l; ii++) {
					_layer.tears.push(Math.random() >= 0.5 ? 'beta' : 'alpha');
				}

				_TEMP_available_layers.push(_layer);
			}
		}

		setAvailableLayers(_TEMP_available_layers);


		// randomly select one type (alpha and beta), then assign up to 99 units to that type and 0 to the other
		let _TEMP_have_alpha = Math.random() >= 0.5;
		setAvailableTearsAlpha(_TEMP_have_alpha ? Math.floor(Math.random() * 999 + 1) : 0);
		setAvailableTearsBeta(!_TEMP_have_alpha ? Math.floor(Math.random() * 999 + 1) : 0);
	}, []);

	const toolsXsSliderOptions: SwiperProps = {
		enabled: true,
		slidesPerView: 2,
		spaceBetween: 20,
		breakpoints: {
			768: {
				enabled: false,
			},
		},
	};

	interface IToolsSlide {
		title: string,
		url: string,
		pretitle?: string,
		img?: string,
		img2x?: string,
	};

	const toolsXsSliderSlides: Array<IToolsSlide> = [
		{
			title: 'Burn your LAYERS',
			url: '#tools',
			pretitle: 'COMING SOON',
			img: '/assets/img/temp/tools_img01_xs.jpg',
			img2x: '/assets/img/temp/tools_img01_xs@2x.jpg',
		},
		{
			title: 'Stake your LAYERS',
			url: '#tools',
			pretitle: 'COMING SOON',
			img: '/assets/img/temp/tools_img02_xs.jpg',
			img2x: '/assets/img/temp/tools_img02_xs@2x.jpg',
		},
		/* {
			title: 'Burn your LAYERS 2',
			url: '#tools',
			pretitle: 'COMING SOON',
			img: '/assets/img/temp/tools_img01_xs.jpg',
			img2x: '/assets/img/temp/tools_img01_xs@2x.jpg',
		},
		{
			title: 'Stake your LAYERS 2',
			url: '#tools',
			pretitle: 'COMING SOON',
			img: '/assets/img/temp/tools_img02_xs.jpg',
			img2x: '/assets/img/temp/tools_img02_xs@2x.jpg',
		}, */
	];

	let isOnSlideChange = false;
	const onSlideChange = (swiper: SwiperCore) => {
		// console.log('onSlideChange', { isOnMiniSlideChange, swiper });
		if (isOnMiniSlideChange) {
			isOnMiniSlideChange = false;
			return;
		}

		setSelectedLayerIndex(swiper.realIndex);
		setSelectedLayer(availableLayers[swiper.realIndex]);
		isOnSlideChange = true;
		// thumbsSwiper?.slideTo(swiper.realIndex);
	};

	let isOnMiniSlideChange = false;
	const onMiniSlideChange = (swiper: SwiperCore) => {
		// console.log('onMiniSlideChange', { isOnSlideChange, swiper });
		if (isOnSlideChange) {
			isOnSlideChange = false;
			return;
		}

		// setSelectedLayerIndex(swiper.realIndex);
		// setSelectedLayer(availableLayers[swiper.realIndex]);
		isOnMiniSlideChange = true;
		// layersSwiper?.slideTo(swiper.realIndex);
	};

	const layersSliderOptions: SwiperProps = {
		loop: true,
		slidesPerView: 1.3,
		centeredSlides: true,
		// watchSlidesProgress: true,
		slideToClickedSlide: true,
		breakpoints: {
			576: {
				slidesPerView: 2.5,
			},
			768: {
				slidesPerView: 1,
			}
		},
		onSlideChange: swiper => onSlideChange(swiper),
		onInit: (swiper) => {
			// setLayersSwiper(swiper);
			onSlideChange(swiper);
		},
		thumbs: { swiper: thumbsSwiper }
	}

	const layersMiniSliderOptions: SwiperProps = {

		modules: [Thumbs],
		watchSlidesProgress: true,
		onSwiper: setThumbsSwiper,
		direction: 'vertical',
		slidesPerView: 3.2,
		navigation: {
			nextEl: '.slide-preview .swiper-button-next',
			prevEl: '.slide-preview .swiper-button-prev',
		},

		// onSlideChange: (swiper) => onMiniSlideChange(swiper),
		onInit: (swiper) => {
			setThumbsSwiper(swiper);
			onMiniSlideChange(swiper);
		},
	}

	return (
		<Layout nftSliderTextColor={'white'} nftSliderTitle={`${collectionNFTCount} uniquely generated artworks to reveal`}>

			<div className={'content'}>

				<section id={'revealing_info'} className={''}>
					<picture className={'responsive bg-texture'}>
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_xxl.png 1x, /assets/img/bkgs/bkg_reveal_top_xxl@2x.png 2x'} media={'(min-width: 1200px'} />
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_xl.png 1x, /assets/img/bkgs/bkg_reveal_top_xl@2x.png 2x'} media={'(min-width: 1200px'} />
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_lg.png 1x, /assets/img/bkgs/bkg_reveal_top_lg@2x.png 2x'} media={'(min-width: 992px'} />
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_md.png 1x, /assets/img/bkgs/bkg_reveal_top_md@2x.png 2x'} media={'(min-width: 768px'} />
						<img srcSet={'/assets/img/bkgs/bkg_reveal_top_xs.png 1x, /assets/img/bkgs/bkg_reveal_top_xs@2x.png 2x'} src={'/assets/img/bkgs/bkg_reveal_top_xs.png'} />
					</picture>

					<div className={'container-fluid'}>
						<div className={'row'}>
							<div className={'col-12'}>

								<input type={'checkbox'} id={'reveal-info-content'} hidden={true} />

								<div className={'top-content row'}>
									<div className={'col-12 col-xl-10 offset-xl-1'}>
										<div className={'d-flex justify-content-between'}>
											<h3 className={'title'}>
												Revealing
											</h3>
											<div className={'btn info-dropdown'}>
												<label htmlFor="reveal-info-content">
													<span className="txt">Info</span>
													<span className="icon"></span>
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className={'info-content'}>
									<div className={'row'}>

										<div className={'about col-12 col-md-5 col-xl-3 offset-xl-1'}>
											LAYERS' Workshop is the main interface to interact with your LAYERS' NFTs - here you can apply tears, assess the traits and rarity of your NFT and have access to periodic special activities that will be continuously developed and launched.
										</div>

										<div className={'instructions col-12 col-md-6 offset-md-1 col-xl-3 offset-xl-1'}>
											<div className={'row'}>
												<h4 className={'col-12 title'}>
													How to reveal your artwork?
												</h4>
												<div className={'col-4 col-md-12 steps'}>
													<span>01. </span>Own a genesis Layers
												</div>
												<div className={'col-4 col-md-12 steps'}>
													<span>02. </span>Own a Tear
												</div>
												<div className={'col-4 col-md-12 steps'}>
													<span>03. </span>Apply your tear
												</div>
											</div>
										</div>

										<div className={'more-info cta col-12 col-xl-2 offset-xl-1'}>
											<h4 className={'title'}>
												Need MORE INFO?
											</h4>
											<div className="row">
												<div className="col-6 offset-3 col-md-2 offset-md-5 col-xl-8 offset-xl-0">
													<div className={'faqs-btn'}>
														<a className="btn" href={'#faqs_block'}>FAQs</a>
													</div>
												</div>
											</div>
										</div>

										<div className={'cta col-12 col-xl-4 offset-xl-4'}>
											<div className={'im-ready cta-btn'}>
												<a className="btn" href={'#interface'}>
													I'M READY, LET'S START
													<span className={'cta-arrow'}>
														<img src={'/assets/img/arrows/arrow_cta_down.svg'} />
													</span>
												</a>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id={'interface'} className={''}>

					{/* XS */}
					<div className={'tools-xs d-md-none'}>
						<picture className={'responsive bg-texture'}>
							<img srcSet={'/assets/img/bkgs/bkg_reveal_top_xs.png 1x, /assets/img/bkgs/bkg_reveal_top_xs@2x.png 2x'} src={'/assets/img/bkgs/bkg_reveal_top_xs.png'} />
						</picture>
						<div className={'container-fluid'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<h3 className={'tools-title'}>TOOLS:</h3>
									<Swiper {...toolsXsSliderOptions}>
										{toolsXsSliderSlides.map((slide, i) =>
											<SwiperSlide key={i}>
												<a className={'tools-item'} href={slide.url} target={'_blank'} rel={'noopener'}>
													<picture className={'responsive'}>
														<img srcSet={`${slide.img} 1x, ${slide.img2x} 2x`} />
													</picture>
													<div className={'fg'}>
														<div className={'pretitle'}>{slide.pretitle}</div>
														<div className={'title'}>{slide.title}</div>
														<img className={'arrow'} src={'/assets/img/arrows/arrow_cta.svg'} />
													</div>
												</a>
											</SwiperSlide>
										)}
									</Swiper>
								</div>
							</div>
						</div>
					</div>
					{/* XS */}


					<div className={'layers-customize container-fluid'}>
						<div className={'row'}>

							<div className={'col-12 col-md-3 col-xl-2 offset-xl-1 d-flex flex-column'}>

								{/* MD */}
								<div className={'section tears-applied row ' + (availableLayers.length ? 'order-0' : 'order-1')}>
									<div className={'col-5 offset-1 col-md-12 offset-md-0'}>
										<h3 className={'interface-title'}>
											LAYERS<br className={'d-md-none'}/> <span className={'d-inline-block'}>#{selectedLayer ? selectedLayer.title : '--'}</span>
										</h3>
										<p>Layer <strong>{availableLayers.length ? selectedLayerIndex + 1 : 0}/{availableLayers.length}</strong> selected</p>
									</div>
									<div className={'col-5 col-md-12'}>
										<h3 className={'section-title'}>TEARS APPLIED:</h3>
										<div className={'tear-types'}>
											{selectedLayer && selectedLayer.tears && selectedLayer.tears[0]
												? <span className={'tear-type active'}>{TearTypes[selectedLayer.tears[0]]}</span>
												: <span className={'tear-type'}>--</span>
											}
											{selectedLayer && selectedLayer.tears && selectedLayer.tears[1]
												? <span className={'tear-type active'}>{TearTypes[selectedLayer.tears[1]]}</span>
												: <span className={'tear-type'}>--</span>
											}
											{selectedLayer && selectedLayer.tears && selectedLayer.tears[2]
												? <span className={'tear-type active'}>{TearTypes[selectedLayer.tears[2]]}</span>
												: <span className={'tear-type'}>--</span>
											}
										</div>
									</div>
								</div>

								<div className={'section tears-to-apply d-none d-md-block ' + (availableLayers.length ? 'order-1' : 'order-2')}>
									<h3 className={'section-title'}>TEARS TO APPLY</h3>
									<div className={'row'}>
										<div className={'col-12'}>
											{availableTearsAlpha
												? <button className={'btn'}>
													<span className={'tears-count'}>{availableTearsAlpha}x</span>
													<span className={'second-line'}><span>Apply</span><strong>α</strong><span>Alpha Tear</span></span>
												</button>
												: <a className={'btn'} href={OPENSEA_TEARS_MARKET_ALPHA} target={'_blank'} rel={'noopener'}>
													<span className={'second-line'}><span>Buy</span><strong>α</strong><span>Alpha Tear</span></span>
												</a>
											}
										</div>
										<div className={'col-12'}>
											{availableTearsBeta
												? <button className={'btn'}>
													<span className={'tears-count'}>{availableTearsBeta}x</span>
													<span className={'second-line'}><span>Apply</span><strong>β</strong><span>Beta Tear</span></span>
												</button>
												: <a className={'btn'} href={OPENSEA_TEARS_MARKET_BETA} target={'_blank'} rel={'noopener'}>
													<span className={'second-line'}><span>Buy</span><strong>β</strong><span>Beta Tear</span></span>
												</a>
											}
										</div>
									</div>
								</div>

								<div className={'section tools-md d-none d-md-block ' + (availableLayers.length ? 'order-2' : 'order-3')}>
									<h3 className={'section-title'}>TOOLS</h3>

									{toolsXsSliderSlides.map((slide, i) =>
										<a key={i} className={'tools-item'} href={slide.url} target={'_blank'} rel={'noopener'}>
											<picture className={'responsive'}>
												<img srcSet={`${slide.img} 1x, ${slide.img2x} 2x`} />
											</picture>
											<div className={'fg'}>
												<div className={'pretitle'}>{slide.pretitle}</div>
												<div className={'title'}>{slide.title}</div>
												<img className={'arrow'} src={'/assets/img/arrows/arrow_cta.svg'} />
											</div>
										</a>
									)}
								</div>

								<div className={'section opensea-btns d-none d-md-block ' + (availableLayers.length ? 'order-3' : 'order-0')}>
									<div className={'row'}>
										<div className={'opensea-btn-holder col-12'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_TEARS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY TEARS</span>
												</a>
											</div>
										</div>
										<div className={'opensea-btn-holder col-12'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_LAYERS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY LAYERS</span>
												</a>
											</div>
										</div>
									</div>
								</div>
								{/* MD */}

							</div>

							<div className={'col-12 col-md-9 col-xl-8 layers-slider ' + (availableLayers.length ? 'with-layers' : 'no-layers')}>

								<div className={'slider d-flex justify-content-between align-items-start'}>

									{availableLayers.length ? <div className={'slide-view'}>


										<div className={'slider-content'}>
											<Swiper {...layersSliderOptions}>
												{buildSwiperSlides(availableLayers, false)}
											</Swiper>
										</div>

									</div> :

										<div className={'slide-view'}>
											<div className={'slide'}>
												<div>
													<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
												</div>
											</div>
											<div className={'slide d-md-none'}>
												<div>
													<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
												</div>
											</div>
											<div className={'slide d-md-none'}>
												<div>
													<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
												</div>
											</div>
											<div className={'get-layer'}>
												<div className={'text'}>
													Get your first from LAYERS market.
												</div>
												<div className={'opensea-btn'}>
													<a href={OPENSEA_LAYERS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon d-flex'}>
														<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
														<span className={'text'}>Buy Layers</span>
													</a>
												</div>
											</div>
										</div>}

									<div className={'d-none d-md-block slide-preview'}>

										<div className={'layer-count'}>
											<div className={'text'}>You have</div>
											<span className={'counter'}>
												{/* @todo Remove "* 30" below */}
												<span>{availableLayers.length * 30}x</span>
												LAYER{availableLayers.length === 1 ? '' : 'S'}
											</span>
										</div>

										{/* <div className={'fade-overlay'}></div> */}

										{availableLayers.length ?
											<>
												<div className={'swiper-button-prev'}>
													<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M1.34326 9L8 2.34326L14.6567 9" stroke="white" strokeWidth="2" />
													</svg>
												</div>
												<div className={'mini-slider-content'}>
													<Swiper {...layersMiniSliderOptions}>
														{buildSwiperSlides(availableLayers.map(layer => {
															let _layer = Object.assign({}, layer);
															_layer.children = <div className={'layer-title-container'}>
																<div className={'layer-title'}>#{_layer.title}</div>
																<div className={'layer-tears d-none d-xxl-flex'}>
																	{_layer.tears && _layer.tears[0]
																		? <span className={'tear-type active'}>{TearTypes[_layer.tears[0]]}</span>
																		: <span className={'tear-type'}>--</span>
																	}
																	{_layer.tears && _layer.tears[1]
																		? <span className={'tear-type active'}>{TearTypes[_layer.tears[1]]}</span>
																		: <span className={'tear-type'}>--</span>
																	}
																	{_layer.tears && _layer.tears[2]
																		? <span className={'tear-type active'}>{TearTypes[_layer.tears[2]]}</span>
																		: <span className={'tear-type'}>--</span>
																	}
																</div>
															</div>;
															return _layer;
														}), false)}
													</Swiper>
												</div>
												<div className={'swiper-button-next'}>
													<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M14.0498 1L7.39307 7.65674L0.736328 1" stroke="white" strokeWidth="2" />
													</svg>
												</div>
											</>
											:
											<div className={'d-xxl-flex'}>
												<div className={'slides d-flex flex-column justify-content-center'}>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
												</div>

												<div className={'slides d-none d-xxl-flex flex-column justify-content-center'}>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
												</div>
											</div>
										}


									</div>

								</div>

								<div className={'section tears-to-apply d-md-none'}>
									<h3 className={'section-title text-center'}>TEARS TO APPLY:</h3>
									<div className={'row'}>
										<div className={'col-5 offset-1'}>
											{availableTearsAlpha
												? <button className={'btn'}>
													<span className={'tears-count'}>{availableTearsAlpha}x</span>
													Apply
													<span className={'second-line'}><strong>α</strong><span>Alpha Tear</span></span>
												</button>
												: <a className={'btn'} href={OPENSEA_TEARS_MARKET_ALPHA} target={'_blank'} rel={'noopener'}>
													Buy
													<span className={'second-line'}><strong>α</strong><span>Alpha Tear</span></span>
												</a>
											}
										</div>
										<div className={'col-5'}>
											{availableTearsBeta
												? <button className={'btn'}>
													<span className={'tears-count'}>{availableTearsBeta}x</span>
													Apply
													<span className={'second-line'}><strong>β</strong><span>Beta Tear</span></span>
												</button>
												: <a className={'btn'} href={OPENSEA_TEARS_MARKET_BETA} target={'_blank'} rel={'noopener'}>
													Buy
													<span className={'second-line'}><strong>β</strong><span>Beta Tear</span></span>
												</a>
											}
										</div>
									</div>
								</div>

								<div className={'section traits'}>
									<div className={'row row-cols-md-3'}>
										{selectedLayer && selectedLayer.traits?.background ?
											<div className={`trait col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>BACKGROUND</div>
												{selectedLayer.traits.background.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.background.value}</div>
												<div className={'count'}>{selectedLayer.traits.background.count} {selectedLayer.traits.background.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>BACKGROUND</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.border ?
											<div className={`trait col-5`}>
												<div className={'desc'}>BORDER</div>
												{selectedLayer.traits?.border?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.border.value}</div>
												<div className={'count'}>{selectedLayer.traits.border.count} {selectedLayer.traits.border.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5`}>
												<div className={'desc'}>BORDER</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.pattern ?
											<div className={`trait col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>PATTERN</div>
												{selectedLayer.traits?.pattern?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.pattern.value}</div>
												<div className={'count'}>{selectedLayer.traits.pattern.count} {selectedLayer.traits.pattern.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>PATTERN</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.type ?
											<div className={`trait col-5`}>
												<div className={'desc'}>TYPE</div>
												{selectedLayer.traits?.type?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.type.value}</div>
												<div className={'count'}>{selectedLayer.traits.type.count} {selectedLayer.traits.type.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5`}>
												<div className={'desc'}>TYPE</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.genesis ?
											<div className={`trait col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>GENESIS</div>
												{selectedLayer.traits?.genesis?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.genesis.value}</div>
												<div className={'count'}>{selectedLayer.traits.genesis.count} {selectedLayer.traits.genesis.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>GENESIS</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.splash ?
											<div className={`trait col-5`}>
												<div className={'desc'}>SPLASH</div>
												{selectedLayer.traits?.splash?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.splash.value}</div>
												<div className={'count'}>{selectedLayer.traits.splash.count} {selectedLayer.traits.splash.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5`}>
												<div className={'desc'}>SPLASH</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
									</div>
								</div>

								<div className={'section opensea-btns d-md-none'}>
									<div className={'row'}>
										<div className={'opensea-btn-holder col-10 offset-1'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_TEARS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY TEARS</span>
												</a>
											</div>
										</div>
										<div className={'opensea-btn-holder col-10 offset-1'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_LAYERS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY LAYERS</span>
												</a>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>


				</section>

			</div>

		</Layout>
	);
};

export default RevealingPage;

export const Head: HeadFC = () => (
	<>
		<title>LAYERS - Revealing</title>
	</>
);
